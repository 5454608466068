@import 'variables';
@import 'mixins';

main {
  background: linear-gradient(to bottom, #E4E4E4, #fffaf0);
  min-height: 100vh;
  padding: 3% 20% 20% 20%;
  
  @include respond-to("phone") {
    padding: 3% 14% 20% 14%;
  }

  @include respond-to("tablet") {
    padding: 3% 10% 20% 10%;
  }

  @include respond-to("large-tablet-small-desktop") {
    padding: 3% 10% 20% 10%;
  }

  #show-form-btn {
    position: absolute;
    top: 5%;
    right: 18%;

    @include respond-to("phone") {
      top: 60px;
      right: 7%;

      @media (max-width: 240px) {
        top: 65px;
      }

      @media (min-width: 630px) {
        top: 70px;
        right: 8%; 
      }
    }

    @include respond-to("tablet") {
      top: 65px;
    }

    @include respond-to("large-tablet-small-desktop") {
      top: 70px;
    }

    @include respond-to("desktop") {
      top: 70px;
    }
  }

  .quotation {
    font-family: serif;
    color: #222222;

    h2 {
      text-align: center;
      font-family: serif;
      color: #74BCD4;

      @include respond-to("large-tablet-small-desktop") {
        margin-top: 3%;
        margin-bottom: 430px;
      }

      @include respond-to("desktop") {
        margin-top: 15%;
        margin-bottom: 350px;
      }
    }

    a {
      color: #222222;
      text-decoration: none;

      &:hover {
        color: #000000;
      }
    }

    @include respond-to("desktop") {
      margin-left: 34%;
    }

    @include respond-to("phone") {
      margin-top: 15%;
    }

    @include respond-to("small-mobile") {
      p {
        font-size: 12px;
      }

      h3 {
        font-size: 16px;
      }
    }
  }

  .document {
    font-family: serif;
    color: #222222;

    h3 {
      color: #74BCD4;
    }

    @include respond-to("phone") {
      margin-top: 18%;

      h3 {
        text-align: center;
        color: #74BCD4;
        margin-top: 20%;
      }
    }

    @include respond-to("tablet") {
      margin-top: 10%;

      h3 {
        text-align: center;
        color: #74BCD4;
        margin-top: 15%;
      }
    }

    @include respond-to("desktop") {
      margin-top: 5%;
      position: relative;
      right: 4%;
    }

    @include respond-to("large-tablet-small-desktop") {
      margin-top: 3%;
    }

    p {
      @include respond-to("small-mobile") {
        font-size: 15px;
      }

      @include respond-to("phone") {
        font-size: 15px;
      }

      @include respond-to("desktop") {
        margin-top: 4%;
        margin-bottom: 5%;
      }

      @include respond-to("large-tablet-small-desktop") {
        margin-top: 5%;
        margin-bottom: 5%;
      }
    }
  }
}
