@import 'variables';
@import 'mixins';

footer {
    background: #444;
    color: white;
    position: fixed;
    bottom: 0;
    width: 100%;

    .info {
        margin-top: 4px;
        padding-right: 20%;
        padding-left: 20%;
        color: white;
        display: flex;
        justify-content: space-between;
        user-select: none;

        @include respond-to("tablet") {
            padding-right: 11%;
            padding-left: 11%;
        }
        @include respond-to("large-tablet-small-desktop") {
            padding-right: 11%;
            padding-left: 11%;
        }
        @include respond-to("phone") {
            display: none;
        }

        ul {
            list-style-type: none;
            margin-bottom: 0%;

            li {
                margin-bottom: 2%;

                a {
                    text-decoration: none;
                    color: white;
                    &:hover {
                        color: #74bcd4;
                    }

                    @include respond-to("tablet") {
                        font-size: 11px;
                    }
                    @include respond-to("large-tablet-small-desktop") {
                        font-size: 13px;
                    }
                    @include respond-to("desktop") {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .last {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 0;

        button {
            user-select: none;
            background-color: transparent;
            color: #74bcd4;
            border: none;
            font-size: 22px; 
            margin-left: 10px;
            cursor: pointer;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @include respond-to("phone") {
            button {
              display: none;
            }
        }
    }

    p {
        text-align: center;
        user-select: none;

        @include respond-to("phone") {
            margin-top: 4%;
        }
    }
}
