@import '../variables';
@import '../mixins';

main {
    min-height: 100vh;
    font-family: serif;
    @include respond-to("phone") {
        padding: 3% 7% 20% 7%;
      }
    h1 {
        text-align: center;
        color: #74bcd4;
        margin-top: 1%;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        @include respond-to("phone") {
            margin-top: 5%;
            font-size: large;
        }
    }
    .feedback-container {
        max-width: 80%;
        margin: 6% auto;
        padding: 40px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        @include respond-to("phone") {
            margin: 10% auto;
            max-width: 100%;
            padding: 6%;
        }
        @include respond-to("tablet") {
            max-width: 90%;
        }
        @include respond-to("large-tablet-small-desktop") {
            max-width: 90%;
        }
    
        .form-group {
            margin-bottom: 10%;
    
            label {
                display: block;
                font-weight: bold;
                margin-bottom: 3%;
                color: #333333;
                @include respond-to("phone") {
                    text-align: center;
                }
            }
    
            input,
            textarea {
                width: 95%;
                padding: 10px;
                border: 1px solid #ccc;
                border-radius: 5px;
                font-size: 16px;
                font-family: serif;
                resize: none;
                @include respond-to("phone") {
                    width: 90%;
                    padding: 5%;
                    &::placeholder {
                        font-size: 13px;
                    }
                }
                &:focus {
                    border-color: #74BCD4;
                    outline: none;
                }
            }
            p {
                text-align: center;
                color: #808080;
                @include respond-to("phone") {
                    font-size: 14px;
                }
            }
    
            textarea {
                height: 100px;
            }
    
            input[type="range"] {
                -webkit-appearance: none; /* Webkitブラウザ用 */
                appearance: none; /* Firefox用 */
                width: 95%; /* スライダーの幅 */
                @include respond-to("phone") {
                    width: 90%;
                  }
              
                /* トラックのスタイル */
                &::-webkit-slider-runnable-track {
                  width: 100%;
                  height: 8px;
                  background: rgba(255, 182, 193, 0.7); /* トラックの色 */
                  border-radius: 5px;
                }
              
                &::-moz-range-track {
                  width: 100%;
                  height: 8px;
                  background: rgba(255, 182, 193, 0.7);
                  border-radius: 5px;
                }
              
                /* つまみ（サム）のスタイル */
                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    width: 8px; /* サムの幅を狭くする */
                    height: 22px; /* サムの高さを調整する */
                    background: rgba(158, 158, 158, 0.7); /* サムの色 */
                    border-radius: 1px; /* 角を少し丸める */
                    cursor: pointer;
                    margin-top: -7px; /* トラックの位置に合わせて調整 */
                    &:hover {
                        background: rgba(158, 158, 158, 1);
                    }
                }
              
                &::-moz-range-thumb {
                    width: 8px; /* サムの幅を狭くする */
                    height: 25px; /* サムの高さを調整する */
                    background: rgba(158, 158, 158, 0.5); /* サムの色 */
                    border-radius: 2px; /* 角を少し丸める */
                    cursor: pointer;
                    &:hover {
                        background: rgba(158, 158, 158, 1);
                    }
                }
            }
        }
    
        .submit-button {
            width: 30%;
            display: block;
            padding: 10px;
            margin: 0 auto;
            background-color: #74bcd4;
            color: white;
            border: none;
            border-radius: 5px;
            font-size: 16px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            transition: all 0.3s ease;
            @include respond-to("phone") {
                width: 50%;
            }
            @include respond-to("small-mobile") {
                width: 40%;
                padding: 6px;
            }
            &:hover {
                background-color: #5DA7BF;
                box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
            }
        
            &:active {
                background-color: #4A92A8;
                transform: translateY(0);
            }
        }
        #feedback {
            color: #333333;
        }
    
        .thank-you-message {
            text-align: center;
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            h2 {
                color: #74bcd4;
            }
        }
    
        .privacy-notice {
            text-align: center;
            margin-top: 6%;
            @include respond-to("phone") {
                font-size: 14px;
                margin-top: 13%;
            }
            @include respond-to("small-mobile") {
                font-size: 11px;
            }
    
            a {
                color: #74bcd4;
                text-decoration: none;
    
                &:hover {
                    color: #5aaec1;
                }
            }
        }
    }
}
