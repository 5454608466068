@import 'variables';
@import 'mixins';

.menu-container {
    position: relative;
  
    @include respond-to("desktop") {
      display: none;
    }
  
    .menu-button {
      background: none;
      border: none;
      padding: 0;
      position: relative;
      left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
      .line {
        width: 25px;
        height: 3px;
        background-color: #74BCD4;
        margin: 3px 0;
        transition: 0.3s;
      }
    }
  
    .menu {
      display: none;
      position: absolute;
      top: 50px;
      left: 0;
      background: white;
      width: 320px;
      border-radius: 4px;
      z-index: 1000;
  
      @include respond-to("small-mobile") {
        width: 240px;
      }
  
      &.open {
        display: block;
      }
  
      a {
        display: block;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 15px;
        text-decoration: none;
        color: black;
        transition: box-shadow 0.3s ease;
  
        &:hover {
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
  