@mixin respond-to($breakpoint) {
    @if $breakpoint == "phone" {
        @media (max-width: $breakpoint-phone) { @content; }
    } @else if $breakpoint == "tablet" {
        @media (min-width: 671px) and (max-width: $breakpoint-tablet) { @content; }
    } @else if $breakpoint == "large-tablet-small-desktop" {
        @media (min-width: 769px) and (max-width: $breakpoint-large-tablet-small-desktop) { @content; }
    } @else if $breakpoint == "desktop" {
        @media (min-width: $breakpoint-desktop) { @content; }
    } @else if $breakpoint == "small-mobile" {
        @media (max-width: 304px) { @content; }
    }
}

@mixin respond-to-height($breakpoint) {
    @if $breakpoint == "short" {
        @media (max-height: 600px) { @content; } // 高さが600px以下
    } @else if $breakpoint == "medium-height" {
        @media (min-height: 601px) and (max-height: 900px) { @content; } // 601px〜900px
    } @else if $breakpoint == "tall" {
        @media (min-height: 901px) { @content; } // 901px以上
    }
}

@mixin responsive-padding {
    padding: 3% 20% 20% 20%;

    // 幅に基づいたレスポンシブデザイン
    @include respond-to("phone") {
      padding: 3% 12% 20% 12%;
    }

    @include respond-to("tablet") {
      padding: 3% 10% 20% 10%;
    }

    @include respond-to("large-tablet-small-desktop") {
      padding: 3% 10% 20% 10%;
    }
}
