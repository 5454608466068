@import '../variables';
@import '../mixins';

main {
    min-height: 100vh;
    font-family: serif;
    background: linear-gradient(to bottom, #E4E4E4, #fffaf0);
    @include respond-to("phone") {
        padding: 3% 7% 20% 7%;
    }

    .contact-container {
        h1 {
            text-align: center;
            margin-bottom: 5%;
            color: #74BCD4;
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            @include respond-to("phone") {
                font-size: 26px;
            }
            @include respond-to("phone") {
                margin-bottom: 10%;
            }
        }

        .thank-you-message {
            text-align: center;

            h2 {
                color: #74BCD4;
                @include respond-to("phone") {
                    font-size: 22px;
                }
                @include respond-to("small-mobile") {
                    font-size: 20px;
                }
            }

            p {
                color: #555;
                font-size: 14px;
                @include respond-to("desktop") {
                    font-size: 16px;
                }
            }
        }

        .contact-form {
            max-width: 80%;
            margin: 0 auto;
            background: #fff;
            padding: 10%;
            border-radius: 8px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

            .form-group {
                margin-bottom: 20px;

                label {
                    display: block;
                    font-weight: bold;
                    color: #333;
                    margin-bottom: 2%;
                }

                input, textarea {
                    width: 95%;
                    padding: 12px;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                    font-size: 16px;
                    transition: border-color 0.3s ease;
                    margin-bottom: 5%;
                    font-family: serif;
                    @include respond-to("phone") {
                        width: 90%;
                        &::placeholder {
                            font-size: 13px;
                        }
                    }
                    @include respond-to("small-mobile") {
                        width: 85%;
                    }
                    &:focus {
                        border-color: #74BCD4;
                        outline: none;
                    }
                }

                textarea {
                    min-height: 120px;
                    resize: none;
                }
            }

            .submit-button {
                width: 20%;
                display: block;
                padding: 10px;
                margin: 0 auto;
                background-color: #74bcd4;
                color: white;
                border: none;
                border-radius: 5px;
                font-size: 16px;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                cursor: pointer;
                transition: all 0.3s ease;
                @include respond-to("phone") {
                    width: 35%;
                }
                @include respond-to("small-mobile") {
                    width: 40%;
                    padding: 6px;
                }
                &:hover {
                    background-color: #5DA7BF;
                    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
                }
            
                &:active {
                    background-color: #4A92A8;
                    transform: translateY(0);
                }
            }
        }
    }
}
