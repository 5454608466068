@import '../variables';
@import '../mixins';

.container {
    background: linear-gradient(to bottom, #E4E4E4, #fffaf0);
    font-family: serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    @include responsive-padding;
    h1 {
        align-self: center;
        color: #74BCD4;
        margin-bottom: 6%;
        @include respond-to("phone") {
            font-size: x-large;
        }
        @include respond-to("small-mobile") {
            font-size: large;
        }
    }
    .color {
        align-self: center;
        color: #74BCD4;
        font-size: 20px;
        @include respond-to("small-mobile") {
            font-size: 16px;
        }
    }
    p {
        @include respond-to("phone") {
            font-size: 14px;
            @include respond-to("small-mobile") {
                font-size: 10px;
            }
        }
    }
    .image-container {
        text-align: center; 
        margin-bottom: 6%;
        @include respond-to("phone") {
            margin-top: 8%;
        }
        .about_image {
            transition: transform 0.3s ease-in-out;
            @include respond-to("phone") {
                width: 100%;
                margin-top: 5%;
                cursor: pointer;
                &.zoomed {
                    transform: scale(1.3);
                    z-index: 10;
                }
            }
            @include respond-to("tablet") {
                width: 100%;
            }
        }   
        .source-link {
            position: relative;
            bottom: 10px;
            background-color: rgba(255, 182, 193, 0.7);
            font-size: 13px;
            border-radius: 3px;
            padding: 5px 10px;
            text-decoration: none;
            color: black;
            transition: all 0.2s ease-in-out;
            @include respond-to("phone") {
                padding: 4px 8px;
                font-size: 10px;
                left: 55%;
            }
            @include respond-to("tablet") {
                left: 50%;
                bottom: 10%;
            }
            @include respond-to("large-tablet-small-desktop") {
                display: block;
                width: 8%;
                left: 82%;
                bottom: 10%;
            }
            @include respond-to("desktop") {
                display: block;
                width: 5%;
                left: 75%;
                bottom: 10%;
            }
            &:hover {
                background-color: rgba(255, 182, 193, 0.9);
            }
        }
    }
    .asahi_link {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        margin-top: 3%;
        margin-bottom: 5%;
        .related.article {
            padding: 5px;
            font-size: 13px;
            border-radius: 8px;
            background-color: rgba(255, 182, 193, 0.4);
            width: 25%;
            color: #333333;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
            transition: all 0.3s ease-in-out; 
            align-self: flex-end;
            @include respond-to("phone") {
                align-self: flex-start;
                width: 100%;
            }
            @include respond-to("tablet") {
                width: 40%;
            }
            @include respond-to("large-tablet-small-desktop") {
                width: 40%;
            }
            .fp {
                color: #444444;
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 3px;
            }
            &:hover {
                background-color: rgba(255, 182, 193, 0.7); 
                box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.2); 
            }
        }
    }
    
    .valuation_basis {
        max-width: 80%;
        align-self: center;
        @include respond-to("phone"){
            margin-top: 20%;
            margin-bottom: 20%;
            &.zoomed {
                transform: scale(1.4);
            }
        }
    }
    
    .administrator-container {
        display: flex;
        justify-content: space-around;
        margin-top: 4%;
        margin-bottom: 4%;
        @include respond-to("phone") {
            flex-direction: column;
        }
        .Logicboy_card {
          border: 1px solid rgba(0, 0, 0, 0.2);
          padding: 16px;
          border-radius: 10px;
          max-width: 60%; 
          display: flex;
          flex-direction: column;
          @include respond-to("phone") {
            max-width: 100%;
          }
          img {
            width: 80%;
            border-radius: 25px;
            align-self: center;
            margin-bottom: 8%;
          }
          .toggle-button {
            background-color: #74BCD4; 
            color: white; 
            border: none; 
            border-radius: 25px; 
            padding: 10px 5px; 
            width: 30%;
            font-size: 16px; 
            cursor: pointer; 
            transition: all 0.3s ease; 
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
            margin: 0 auto;
            @include respond-to("small-mobile") {
                width: 50%;
                padding: 7px 3px;
                font-size: 13px;
            }
            
            &:hover {
              background-color: #5aaec1;
              box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
            }
          
            &:active {
              background-color: #5199a8;
              box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
              transform: translateY(2px);
            }
          }
        }
      }
    h3 {
        color: #444444;
        @include respond-to("phone") {
            font-size: 16px;
        }
    }
}