@import 'variables';
@import 'mixins';

.share-buttons {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5%;
  
    @include respond-to("desktop") {
      margin: 3% 20% 5% 25%;
    }
  
    .menu-button {
      cursor: pointer;
      color: #4a9ab2;
  
      &:hover {
        color: #357a9d;
      }
    }
  
    .menu-options {
      position: absolute;
      top: 100%;
      right: 0;
      background-color: white;
      border: 1px solid #ddd;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      z-index: 10;
      width: 200px;
  
      button {
        display: block;
        color: black;
        width: 100%;
        padding: 10px;
        background: none;
        border: none;
        text-align: left;
        cursor: pointer;
        font-size: 14px;
        background-color: #fffaf0;
  
        &:hover {
          background-color: #f0f0f0;
          color: #4a9ab2;
        }
  
        &:active {
          background-color: #f0f0f0;
          color: #357a9d;
        }
      }
    }
  }
  