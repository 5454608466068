@import 'variables';
@import 'mixins';

main {
  background: linear-gradient(to bottom, #E4E4E4, #fffaf0);
  min-height: 100vh;
  padding: 3% 20% 20% 20%;

  @include respond-to("phone") {
    padding: 3% 14% 20% 14%;
  }

  @include respond-to("tablet") {
    padding: 3% 10% 20% 10%;
  }

  @include respond-to("large-tablet-small-desktop") {
    padding: 3% 10% 20% 10%;
  }

  .error-message {
    opacity: 0;
    transition: opacity 0.5s ease-out;
    margin-top: 5%;
    text-align: center;
    color: #74BCD4;
    font-family: serif;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    &.visible {
      opacity: 1;
    }

    @include respond-to("desktop") {
      font-size: 20px;
    }

    @include respond-to("large-tablet-small-desktop") {
      font-size: 18px;
    }
  }

  #evaluation-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.5s ease;
    opacity: 1;
    user-select: none;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    p {
      color:#74BCD4;
      @include respond-to("phone") {
        margin-bottom: 7%;
        font-size: 14px;
      }
    }

    #article-url {
      width: 70%;
      height: 40px;
      font-size: 17px;
      font-family: 'serif';
      border: 0.5px solid #fffaf0;
      background-color: white;
      border-radius: 3px;

      &:focus {
        border-color: #74BCD4;
        outline: none;
      }

      @include respond-to("phone") {
        width: 100%;
        height: 35px;
      }

      @include respond-to("desktop") {
        width: 90%;
      }
    }

    #evaluate-btn {
      user-select: none;
      width: 5%;
      height: 35px;
      color: white;
      font-size: 16px;
      background-color: #74BCD4;
      border: none;
      border-radius: 4px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: all 0.3s ease;
      text-align: center;
      font-weight: normal;

      &:hover {
        background-color: #5DA7BF;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
      }

      &:active {
        background-color: #4A92A8;
        transform: translateY(0);
      }

      @include respond-to("phone") {
        width: 22%;
        margin-top: 7%;
        font-size: 14px;
      }

      @include respond-to("small-mobile") {
        margin-top: 1%;
        width: 28%;
        font-size: 13px;
      }

      @include respond-to("tablet") {
        width: 9%;
        margin-top: 2%;
      }

      @include respond-to("large-tablet-small-desktop") {
        width: 9%;
        margin-top: 2%;
      }

      @include respond-to("desktop") {
        width: 7%;
        margin-top: 3%;
      }
    }
  }

  #loading-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-top: 40px;

    @include respond-to("small-mobile") {
      margin-top: 40px;
    }

    .loader {
      border: 4px solid #f3f3f3;
      border-radius: 50%;
      border-top: 4px solid #74BCD4;
      width: 40px;
      height: 40px;
      animation: spin 2s linear infinite;
      -webkit-animation: spin 2s linear infinite; /* Safariや古いChrome用 */
      -moz-animation: spin 2s linear infinite;    /* Firefox用 */

      @include respond-to("small-mobile") {
        width: 30px;
        height: 30px;
      }
      @include respond-to("phone") {
        width: 33px;
        height: 33px;
      }
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
    
    @-webkit-keyframes spin {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }
    
    @-moz-keyframes spin {
      0% { -moz-transform: rotate(0deg); }
      100% { -moz-transform: rotate(360deg); }
    }
  }

  #show-form-btn {
    position: absolute;
    top: 5%;
    right: 18%;

    @include respond-to("phone") {
      top: 60px;
      right: 7%;

      @media (max-width: 240px) {
        top: 65px;
      }

      @media (min-width: 630px) {
        top: 70px;
        right: 8%;
      }
    }

    @include respond-to("tablet") {
      top: 65px;
    }

    @include respond-to("large-tablet-small-desktop") {
      top: 70px;
    }

    @include respond-to("desktop") {
      top: 70px;
    }

    button {
      background-color: #74BCD4;
      color: #fff;
      padding: 8px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      user-select: none;

      @include respond-to("phone") {
        font-size: 12px;
        padding: 8px;
      }
      @include respond-to("small-mobile") {
        font-size: 10px;
        padding: 6px;
      }

      @include respond-to("tablet") {
        padding: 8px;
        font-size: 11px;
      }

      @include respond-to("large-tablet-small-desktop") {
        padding: 8px;
      }

      &:hover {
        background-color: #4a9ab2;
      }
    }
  }
}
