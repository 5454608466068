@import '../variables';
@import '../mixins';

main {
  background: linear-gradient(to bottom, #E4E4E4, #fffaf0);

  .support-container {
    text-align: center;
    padding: 2rem;
    font-family: serif;

    h1 {
      color: #74BCD4;
    }

    p {
      font-size: 18px;
      margin-bottom: 2rem;
    }

    .amount-selection {
      button {
        background-color: #74BCD4;
        color: white;
        padding: 10px 20px;
        margin: 0 10px;
        border: none;
        border-radius: 25px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #5aaec1;
        }
      }
    }

    .custom-amount {
      margin-top: 2rem;

      input {
        padding: 5px;
        border-radius: 5px;
        border: 1px solid #ccc;
        width: 100px;
        margin-left: 10px;
      }
    }

    .payment-section {
      margin-top: 3rem;

      .support-button {
        background-color: #74BCD4;
        color: white;
        padding: 10px 30px;
        border: none;
        border-radius: 25px;
        font-size: 16px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #5aaec1;
        }
      }
    }
  }
}
