@import 'variables';
@import 'mixins';

header {
    background: #E4E4E4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15%;

    @include respond-to("desktop") {
        padding: 0 15%;
    }
    @include respond-to("phone") {
        padding: 1% 0%;
    }
    @include respond-to("tablet") {
        padding: 0% 15%;
    }
    .logo {
        cursor: pointer;
        @include respond-to("phone") {
            position: relative;
            right: 3%;
        }
        @include respond-to("tablet") {
            position: relative;
            right: 30%;
        }
        @include respond-to("large-tablet-small-desktop") {
            position: relative;
            right: 30%;
        }
    }

    nav {
        a {
            position: relative;
            opacity: 0.7;
            margin-right: 5px;
            text-decoration: none;
            color: black;
            &:hover {
                opacity: 1;
            }
            @include respond-to("phone") {
                display: none;
            }
        }
        .fa-language {
            @include respond-to("phone") {
                position: relative;
                right: 60px;
                font-size: 22px;
                color: #74BCD4;
            }
            @include respond-to("small-mobile") {
                position: relative;
                right: 35px;
            }
            @include respond-to("tablet") {
                font-size: 30px;
                position: relative;
                left: 5%;
            }
            @include respond-to("large-tablet-small-desktop") {
                font-size: 30px;
                position: relative;
                left: 5%;
            }
            @include respond-to("desktop") {
                position: relative;
                left: 5%;
                font-size: 27px;
            }
        }
    }
}