@import '../variables';
@import '../mixins';

.container {
    background: linear-gradient(to bottom, #E4E4E4, #fffaf0);
    font-family: serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    @include responsive-padding;

    h1 {
        text-align: center;
        color: #74BCD4;
        margin-bottom: 6%;
    }
    h3 {
        color: #444444;
        text-align: start;
        @include respond-to("small-mobile") {
            font-size: 13px;
        }
    }
}