@import '../variables';
@import '../mixins';

.updates-container {
    font-family: serif;
    padding: 2% 10%;
    background: linear-gradient(to bottom, #E4E4E4, #fffaf0);
    min-height: 100vh;
    @include respond-to("small-mobile") {
        min-height: 120vh;
    }

    h1 {
        text-align: center;
        margin-bottom: 40px;
        color: #74BCD4;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }

    .updates-list {
        max-width: 800px;
        margin: 0 auto;

        .update-item {
            background: #fff;
            padding: 20px;
            margin-bottom: 20px;
            border-radius: 8px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            transition: transform 0.2s ease;

            &:hover {
                transform: translateY(-5px);
            }

            h3 {
                margin-bottom: 8px;
                font-size: 18px;
                color: #333;
                @include respond-to("small-mobile") {
                    text-align: center;

                }
            }

            p {
                color: #666;
                line-height: 1.6;
            }
        }
    }
}
