@import 'variables';
@import 'mixins';

.chart-container {
    display: flex;
    flex-direction: column;

    @include respond-to("desktop") {
        width: 300px;
        height: 300px;
        position: absolute;
        top: 15%;
        left: 50%;
        transform: translate(-50%, -30%);
        transition: all 1s ease;
    }

    @include respond-to("phone") {
        width: 100%;
        height: auto;
        margin-top: 15%;
        justify-content: center;
        transform: none;
    }

    @include respond-to("small-mobile") {
        width: 100%;
        height: auto;
        margin-top: 20%;
        margin-left: 2%;
        transform: none;
    }

    @include respond-to("tablet") {
        width: 88%;
        height: auto;
        margin-top: 2%;
        margin-left: 4%;
        transform: none;
    }

    @include respond-to("large-tablet-small-desktop") {
        width: 230px;
        height: 230px;
        position: absolute;
        top: 10%;
        left: 50%;
        transform: translate(-50%, -10%);
        transition: all 1s ease;
    }

    &.expanded {
        @include respond-to("desktop") {
            width: 90%;
            height: 100%;
            top: 5%;
            left: 50%;
        }
        @include respond-to("large-tablet-small-desktop") {
            width: 70%;
            height: 500px;
            position: absolute;
            top: 1%;
        }
    }

    &.shrunk {
        @include respond-to("desktop") {
            width: 30vw;
            height: 270px;
            top: 5vh;
            left: 10vw;
            transform: none;
        }
        @include respond-to("large-tablet-small-desktop") {
            width: 30vw;
            height: 230px;
            top: 9vh;
            left: 13vw;
            transform: none;
        }
    }

    .chart-content {
        @include respond-to("phone") {
            width: 100%;
            height: 22vh;
            background-color: rgba(255, 250, 240, 0);
            text-align: center;

            @media (min-width: 400px) {
                height: 250px;
            }
            @media (max-height: 240px) {
                height: 50vh;
            }
        }

        @include respond-to("small-mobile") {
            height: 110px;
        }

        @include respond-to("tablet") {
            width: 100%;
            height: 270px;
            background-color: rgba(255, 250, 240, 0);
        }

        @include respond-to("large-tablet-small-desktop") {
            width: 100%;
            height: 100%;
            background-color: rgba(255, 250, 240, 0);
        }

        @include respond-to("desktop") {
            width: 100%;
            height: 100%;
            background-color: rgba(255, 250, 240, 0);
        }
    }

    .average-score {
        opacity: 0;
        color: #4a9ab2;
        font-size: large;
        transition: opacity 1s ease-in-out;
        text-align: center;

        @include respond-to("small-mobile") {
            font-size: medium;
            margin-bottom: 10%;
        }

        @include respond-to("phone") {
            margin-bottom: 10%;
        }

        @include respond-to("tablet") {
            margin-top: 10%;
            margin-bottom: 5%;
        }

        @include respond-to("large-tablet-small-desktop") {
            margin-top: 25%;
            margin-bottom: 5%;
        }

        @include respond-to("desktop") {
            margin-top: 25%;
            margin-bottom: 5%;
        }

        &.visible {
            opacity: 1;
        }
    }
}
