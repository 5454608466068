@import '../variables';
@import '../mixins';

.faq-container {
    background: linear-gradient(to bottom, #E4E4E4, #fffaf0);
    min-height: 100vh;
    padding: 2% 0%;
    font-family: serif;
    @include respond-to("small-mobile") {
        padding: 0;
    }
    @include respond-to("phone") {
        padding: 0;
    }
    h1 {
        text-align: center;
        margin-bottom: 30px;
        color: #74BCD4;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        @include respond-to("phone") {
            font-size: xx-large;
        }
    }

    .faq-section {
        margin-bottom: 20px;

        h2 {
            color: #74BCD4;
            font-size: 21px;
            margin-bottom: 10px;
            user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            @include respond-to("phone") {
                text-align: center;
            }
        }

        details {
            background: #fff;
            padding: 15px;
            margin-bottom: 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            cursor: pointer;

            summary {
                font-weight: bold;
                font-size: 16px;
                color: #333;
            }

            p {
                margin-top: 10px;
                color: #666;
                @include respond-to("phone") {
                    font-size: 14px;
                }   
            }
        }
    }
}
