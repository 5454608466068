@import 'variables';
@import 'mixins';

.article-quote {
    margin-top: 5%;

    @include respond-to("phone") {
        font-size: 14px;
    }

    @include respond-to("large-tablet-small-desktop") {
        margin-left: 47%;
    }

    .article-quote-header {
        padding: 1%;
        background-color: #dcdcdc;
        border-radius: 8px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
        min-height: 17vh;

        &:hover {
            background-color: #e0e0e0;
        }

        .article-quote-image {
            width: 40%;
            height: 150px;
            object-fit: cover;

            @include respond-to("phone") {
                width: 80%;
                margin-left: 9%;
            }
        }
    }
}
